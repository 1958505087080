import { atom } from 'recoil';

var d = new Date();
var day = d.toLocaleString("en-CA", { day: "2-digit" });
var month = d.toLocaleString("en-CA", { month: "2-digit" });
var year = d.toLocaleString("en-CA", { year: "numeric" });

export const saved_fromDate = atom({
    key: 'saved_fromDate',
    default: (year + "-" + month + "-" + "01"),
});
export const saved_toDate = atom({
    key: 'saved_toDate',
    default: (year + "-" + month + "-" + day),
});
export const loading = atom({
    key: 'loading',
    default: false,
});

export const summaryData = atom({
    key: 'summaryData',
    default: [],
});
export const home_count = atom({
    key: 'home_count',
    default: (""),
});
export const divisionData = atom({
    key: 'divisionData',
    default: {
        divisionList:[],
        divisionId:"",
        subDivisionId:"",
        sectionId:"",
        areaName:"",
        status_code:"",
        exception_code:"",
        mr_id:"",
        exceptionStatus:[], 
    }
});
export const subdivisionData = atom({
    key: 'subdivisionData',
    default: [],
});
export const sectionData = atom({
    key: 'sectionData',
    default: [],
});
export const areaData = atom({
    key: 'areaData',
    default: [],
});
export const MrData = atom({
    key: 'MrData',
    default: [],
});

export const exception_chart = atom({
    key: 'exception_chart',
    default: [],
});

export const exception_report = atom({
    key: 'exception_report',
    default: [],
});

export const exception_table = atom({
    key: 'exception_Table',
    default: [],
});

export const mr_exception_table = atom({
    key: 'mr_exception_Table',
    default: [],
});


export const selectedFiltersAtom = atom({
    key: 'selectedFilters',
    default: {
      subdivision: "",
      section: "",
      area: "",
      meterReader:"" ,
      exception_code:""
    },
  });

  export const selectedMr = atom({
    key: 'selectedMr',
    default: {
        mr_id:"",
        exception_code:"",
        exceptionSummaryData:[]
    },
  });


  export const userAuthenticatedState = atom({
    key: 'userAuthenticatedState',
    default: false, 
  });


  export const MrExceptionReportForSummaryData = atom({
    key: 'selectedMr',
    default: {
        exception_code:"",
        status_code:"",
        group_by_key:"subdivision_id",
        group_by_value:"",
        summaryData:[],
    },
  });


  export const mrPerformanceReport = atom({
    key: 'mrPerformanceReport',
    default: {
      subdivision: "",
      section: "",
      area: "",
      meterReader:"",
      mr_id:"",
      exception_code:"",
      status_code:"",
      performanceReport:[],
      meterReaderData:[],
    },
  });

  export const consumerSearchList = atom({
    key: 'consumerSearchList',
    default: {
        consumerId:"",
        ConsumerList:[]
    },
  });

export const allReadings=atom({
    key:'allreadings',
    default:[],
})

export const allReadingsExpanded=atom({
    key:'allReadingsExpanded',
    default:[],
})
